import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as BackArrow } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CheckMark } from '../../../../assets/icons/CheckCircle.svg';
import Flutterwave from '../../../../assets/icons/Flutterwave.svg';
import Monify from '../../../../assets/icons/monify.svg';
import Paystack from '../../../../assets/icons/paystack.svg';
// import Remita from '../../../../assets/icons/Remita.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/sb-settings.svg';
import { Button } from '../../../../components/buttons';
import PaymentGatewayForm from '../../../../components/forms/payment-gateway-form';
import { PageTitle, Text } from '../../../../containers/MesssageContainers';
import { FlexCentredRow, FlexDiv, FlexRowSpaceBetween } from '../../../../containers/ScreenContainers';
import { getAllActivePaymentGateways } from '../../../../redux/bursary/actions';

const Card = tw.div`
    bg-white
    py-[3.5rem]
    px-[3.2rem]
    w-full
    // mb-[2.4rem]
    no-underline
    rounded-[1.6rem]
`;

const PaymentGateway = [
    { name: 'Paystack', src: Paystack, enabled: false },
    { name: 'Monnify', src: Monify, enabled: false },
    { name: 'Flutterwave', src: Flutterwave, enabled: false },
    // { name: 'Remita', src: Remita, enabled: false },
];

const ReceivingAccounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [gatewayName, setGatewayName] = useState('');
    const [activePayments, setActivePayments] = useState([]);
    const [gatewayId, setGatewayId] = useState('');
const [refetchGateways, setRefetchGateways] = useState(false);

const providersToUpdateMap = new Map(activePayments?.map((provider) => [provider.provider.toLowerCase(), provider]));

const updatedPaymentGateway = PaymentGateway.map((provider) => ({
    ...provider,
    enabled: providersToUpdateMap.has(provider.name.toLowerCase()),
    id: providersToUpdateMap.get(provider.name.toLowerCase())?._id || null,
}));

useEffect(() => {
    const getActiveGateways = async () => {
        const data = await dispatch(getAllActivePaymentGateways());
        setActivePayments(data);
    };

    getActiveGateways();
}, [dispatch, refetchGateways]);

const handleShowModal = (gateway) => {
    setGatewayName(gateway.name);
    setGatewayId(gateway.id);
    setShowModal(true);
};

// if (isLoading) return <Loader />;

return (
    <div>
        <FlexRowSpaceBetween>
            <FlexCentredRow onClick={() => navigate(-1)} className="hover:cursor-pointer gap-[1rem]">
                <BackArrow className="mr-[8px]" />
                <PageTitle as="span" size="1.6rem" color="#6366F1" weight="400" lineHeight="2.8rem">
                    Settings
                </PageTitle>
                <Text weight="500" size="1.4rem">
                    /
                </Text>
                <Text weight="400" size="1.6rem">
                    Payment Gateway
                </Text>
            </FlexCentredRow>
        </FlexRowSpaceBetween>

        <PageTitle align="left" size="2.3rem" color="#1F2937" weight="600" top="3rem" bottom="3rem">
            Payment Gateway
        </PageTitle>

        <div className="flex flex-col gap-[1.6rem]">
            {updatedPaymentGateway?.map((gateway, index) => (
                <Card key={index}>
                    <div className="flex items-center justify-between">
                        <img
                            src={gateway.src}
                            alt=""
                            loading="lazy"
                            className={`${gateway.enabled ? '' : 'opacity-50'} w-[100px]`}
                        />
                        <FlexDiv className="gap-[0.8rem] items-center">
                            {gateway.enabled ? <CheckMark fill="#34D399" /> : <CheckMark fill="#D1D5DB" />}
                            <Text color={gateway.enabled ? '#059669' : '#D1D5DB'} align="left" size="1.4rem">
                                {gateway.enabled === true ? 'Enabled' : 'Disabled'}
                            </Text>
                        </FlexDiv>
                        <Button
                            onClick={() => handleShowModal(gateway)}
                            border="0.5px solid #E5E7EB"
                            className="flex items-center gap-[0.8rem] w-[13.1rem]"
                        >
                            <SettingsIcon fill="#1F2937" />{' '}
                            <Text size="1.4rem" color="#1F2937" weight="500">
                                Configure
                            </Text>
                        </Button>
                    </div>
                </Card>
            ))}
        </div>

        <PaymentGatewayForm
            show={showModal}
            onClose={() => setShowModal(false)}
            gatewayName={gatewayName}
            gatewayId={gatewayId}
            refetch={() => setRefetchGateways((prev) => !prev)}
        />
    </div>
);
};

export default ReceivingAccounts;
