import React, { useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/delete-red.svg';
import { ReactComponent as Dropdown } from '../../../../../assets/icons/dropdown.svg';
import { ActionsPopup, ConfirmActionDialogue } from '../../../../../components';
import { Button } from '../../../../../components/buttons';
import TableWithUtils from '../../../../../components/table-with-utils';
import { Text } from '../../../../../containers/MesssageContainers';
import { RelativeContainer } from '../../../../../containers/ScreenContainers';
import useSearch from '../../../../../hooks/useSearch';
import { deleteDebtorFee } from '../../../../../redux/bursary/actions';
import currencyFormatter from '../../../../../utils/formatCurrency';

const fields = [
    {
        header: 'Fee Type',
        accessorKey: 'feeType',
    },
    {
        header: 'Session',
        accessorKey: 'session',
    },
    {
        header: 'Amount Owed',
        accessorKey: 'amountOwed',
        cell: (props) => <p>{currencyFormatter.format(props.getValue() || 0)}</p>,
    },
    {
        header: 'Created At',
        accessorKey: 'createdAt',
        cell: (props) => <p>{props.getValue() ? format(new Date(props.getValue()), 'dd/MM/yyyy') : 'N/A'}</p>,
    },
    {
        header: 'Span Of Fee',
        accessorKey: 'span',
        cell: (props) => <p>{SPAN_LOOKUP[props.getValue()]}</p>,
    },
];

const SPAN_LOOKUP = {
    'first-semester': 'First Semester',
    'second-semester': 'Second Semester',
    'both-semesters': 'Both Semester',
};

const DebtHistory = ({ details, setToggleRefetch }) => {
    const dispatch = useDispatch();

    const { isLoading } = useSelector((state) => state.bursary);

    const [selectedItem, setSelectedItem] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openModal, setOpenModal] = useState('');

    const [searchValue, setSearchValue] = useState('');
    const filteredResults = useSearch(searchValue, details.debts, ['feeType']);

    const handleDelete = async () => {
        const payload = {
            debtIdToDelete: selectedItem._id,
        };

        const res = await dispatch(deleteDebtorFee(details.studentId, payload));
        if (res) {
            setToggleRefetch((prevState) => !prevState);
            setOpenModal('');
        }
    };

    return (
        <>
            <div className="bg-[#fff] rounded-t-[16px] mb-10 mt-4">
                <div className="flex items-center justify-between px-[24px] pt-[18px] pb-[0px] mb-[-20px]">
                    <Text weight="500" size="1.9rem">
                        Debt History
                    </Text>

                    <RelativeContainer>
                        <Button bgColor="#6366F1" color="#fff" onClick={() => setOpenDropdown(true)}>
                            Actions
                            <Dropdown />
                        </Button>
                        <ActionsPopup
                            open={openDropdown}
                            close={() => setOpenDropdown(false)}
                            items={[
                                {
                                    icon: <DeleteIcon />,
                                    name: 'Delete Fee',
                                    disabled: !selectedItem,
                                    click: () => setOpenModal('delete'),
                                },
                            ]}
                        />
                    </RelativeContainer>
                </div>
                <TableWithUtils
                    fields={fields}
                    data={filteredResults || []}
                    showTableUtils
                    searchable
                    loading={isLoading}
                    // showFilter
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                />
            </div>

            <ConfirmActionDialogue
                show={openModal === 'delete'}
                close={() => setOpenModal('')}
                title="Delete this fee?"
                subtitle="This fee will be deleted permanently."
                bodyText="Do you want to continue?"
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                isLoading={isLoading}
            />
        </>
    );
};

export default DebtHistory;
