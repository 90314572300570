import React from 'react';

import { Field, ErrorMessage } from 'formik';
import tw, { styled } from 'twin.macro';

import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down-ash.svg';
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrowup-ash.svg';
import { ReactComponent as ClosedEyeIcon } from '../../../assets/icons/closed-eye.svg';
import { ReactComponent as OpenEyeIcon } from '../../../assets/icons/open-eye.svg';
import { FlexCentredCol } from '../../../containers/ScreenContainers';

const Container = styled.div`
    width: ${({ width }) => width || '100%'};
`;

const InputField = tw(Field)`
    pl-3
    outline-none
    // focus:ring-[#6366F1]
    // focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    text-[#1F2937]
`;

const StyledInputField = styled(InputField)`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '4rem'};
    // -webkit-appearance: none;
`;

const IconBox = tw.div`
    absolute
    // top-[50%]
    right-[5px]
    z-10
`;

const Label = tw.label`

`;

const FormikTextInput = ({
    as,
    type = 'text',
    children,
    value,
    name,
    errors,
    placeholder,
    className,
    width,
    height,
    onChange,
    inputType,
    togglePassword,
    label = '',
    disabled,
    step,
    min,
    max,
    handleIncrement,
    handleDecrement,
    showSpinBtns = false,
    labelClass,
    autoCapitalize = 'off',
    
}) => {
    return (
        <Container className="relative" width={width}>
            {label && (
                <Label
                    className={`${labelClass ? labelClass : 'text-[#1F2937] font-medium text-[1.4rem]'}`}
                    htmlFor={name}
                >
                    {label}
                </Label>
            )}
            <div className="flex items-center justify-between border-[1px] border-[#E5E7EB] rounded">
                <StyledInputField
                    id={name}
                    type={type}
                    as={as}
                    step={step}
                    placeholder={placeholder}
                    className={`${errors[name] && 'focus:ring-[#EF4444]'} ${className} ${
                        disabled && 'text-[#D1D5DB]'
                    } appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                    value={value}
                    name={name}
                    height={height}
                    width={width}
                    onChange={onChange && onChange}
                    disabled={disabled}
                    min={min}
                    max={max}
                    autoCapitalize={autoCapitalize}
                >
                    {children}
                </StyledInputField>
                {inputType === 'password' && (
                    <IconBox onClick={togglePassword}>
                        {type === 'text' ? <OpenEyeIcon fill="#D1D5DB" /> : <ClosedEyeIcon />}
                    </IconBox>
                )}
                {type === 'number' && showSpinBtns && (
                    <FlexCentredCol className="absolute inset-y-0 top-[40%] right-4 gap-[1.087rem]">
                        <ArrowUpIcon className="hover:cursor-pointer" onClick={() => handleIncrement(value, name)} />
                        <ArrowDownIcon className="hover:cursor-pointer" onClick={() => handleDecrement(value, name)} />
                    </FlexCentredCol>
                )}
            </div>

            <ErrorMessage name={name}>
                {(errorMessage) => <span className="text-[#EF4444] text-[1.2rem]">{errorMessage}</span>}
            </ErrorMessage>
        </Container>
    );
};

export default FormikTextInput;
