import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    allHostelsData: {},
    hostelDetails: {},
    allBlocks: [],
    blockDetails: {},
    allRooms: [],
    roomDetails: {},
    allBedspaces: [],
    bedspaceDetails: {},
};

export const hostelSlice = createSlice({
    name: 'hostel',
    initialState,
    reducers: {
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        GET_All_HOSTELS: (state, action) => {
            state.allHostelsData = action.payload;
        },

        GET_All_BLOCKS: (state, action) => {
            state.allBlocks = action.payload;
        },
        GET_BLOCK_BY_ID: (state, action) => {
            state.blockDetails = action.payload;
        },
        GET_All_ROOMS: (state, action) => {
            state.allRooms = action.payload;
        },
        GET_ROOM_BY_ID: (state, action) => {
            state.roomDetails = action.payload;
        },
        GET_All_BEDSPACES: (state, action) => {
            state.allBedspaces = action.payload;
        },
        GET_BEDSPACE_BY_ID: (state, action) => {
            state.bedspaceDetails = action.payload;
        },
    },
});

export const {
    GET_All_HOSTELS,
    GET_All_BLOCKS,
    GET_BLOCK_BY_ID,
    LOADING,
    STOP_LOADING,
    GET_All_ROOMS,
    GET_ROOM_BY_ID,
    GET_All_BEDSPACES,
    GET_BEDSPACE_BY_ID,
} = hostelSlice.actions;

export default hostelSlice.reducer;
