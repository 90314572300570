import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_All_HOSTELS,
    LOADING,
    STOP_LOADING,
    GET_BLOCK_BY_ID,
    GET_All_BLOCKS,
    GET_All_ROOMS,
    GET_ROOM_BY_ID,
} from './slice';

export const addNewHostel = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());

    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const headers = {
            'Content-Type': 'multi-part/form-data',
        };

        const response = await axiosInstance.post(
            `${currentAPI}hostel/${currentUnit?._id}/create_hostel`,
            payload,
            headers,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editNewHostel = (payload, headers, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/edit_hostel/${id}`,
            payload,
            headers,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const changeRoomStatus = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/change_room_status/${roomId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const manageRoomReservation = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/manage_room_reservations/${roomId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHostelById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_hostel/${id}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllHostel = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_all_hostels?${queries}`);
        if (response.status === 200) {
            dispatch(GET_All_HOSTELS(response.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteHostel = (hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(
            `${currentAPI}hostel/${currentUnit?._id}/delete_hostel/${hostelId}`,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getBlockById = (blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_block/${blockId}`);
        if (response.status === 200) {
            dispatch(GET_BLOCK_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllBlocks = (hostelId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}hostel/${currentUnit?._id}/get_all_blocks/${hostelId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_All_BLOCKS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteBlock = (blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}hostel/${currentUnit?._id}/delete_block/${blockId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createBlock = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}hostel/${currentUnit?._id}/create_block/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBlock = (payload, hostelId, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/edit_block/${id}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addRoom = (payload, hostelId, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}hostel/${currentUnit?._id}/create_room/${hostelId}/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editRoom = (payload, roomId, blockId, hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/edit_room/${roomId}/${blockId}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getRoomById = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_room/${roomId}`);
        if (response.status === 200) {
            dispatch(GET_ROOM_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllRooms = (blockId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}hostel/${currentUnit?._id}/get_all_rooms/${blockId}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_All_ROOMS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteRoom = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}hostel/${currentUnit?._id}/delete_room/${roomId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addBedspace = (payload, hosteld, roomId, blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}hostel/${currentUnit?._id}/add_bed_space/${hosteld}/${blockId}/${roomId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editBedSpace = (payload, id, roomId, blockId, hostelId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}hostel/${currentUnit?._id}/edit_bed_space/${id}/${roomId}/${blockId}/${hostelId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getBedspaceById = (bedspaceId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_bedspace/${bedspaceId}`);
        if (response.status === 200) {
            dispatch(GET_ROOM_BY_ID(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBedspaces = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}hostel/${currentUnit?._id}/get_all_bedspaces/${roomId}`);
        if (response.status === 200) {
            dispatch(GET_All_ROOMS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteBedspace = (roomId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}hostel/${currentUnit?._id}/delete_room/${roomId}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createRoom = (payload, roomId, blockId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}hostel/${currentUnit?._id}/create_room/${roomId}/${blockId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
