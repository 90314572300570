import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import {
    GET_ALL_AGENT_FEES,
    GET_ALL_AGENTS,
    GET_ALL_BANKS,
    GET_ALL_DEBTORS,
    GET_ALL_DISCOUNTS,
    GET_ALL_FEES,
    GET_ALL_RECEIVING_ACCOUNTS,
    GET_DISCOUNT_DETAILS,
    GET_FEE,
    GET_FEE_DETAILS,
    GET_INSTALLMENT_DETAILS,
    GET_PAID_DETAILS,
    GET_PAYMENT_HISTORY,
    GET_STUDENT_PAYMENT_HISTORY,
    GET_STUDENT_RECEIPT,
    GET_UNPAID_DETAILS,
    LOADING,
    STOP_LOADING,
} from './slice';

export const addFee = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/fees`, payload);
        const successMessage = response?.data?.data.message || 'Fee Created Successfully!';
        if (response.status === 201 || response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllFees = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/fees?${queries}`);
        if (response.status === 200) {
            dispatch(GET_ALL_FEES(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createReceivingAccount = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}payments/${currentUnit?._id}/create-receiving-account`,
            payload,
        );
        if (response.status === 200) {
            const successMessage = response?.data?.data.message || 'Receiving Account Created Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editReceivingAccount = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/edit-receiving-account/${id}`,
            payload,
        );
        const successMessage = response?.data?.data.message || 'Receiving Account Updated Successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllReceivingAccount = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/all-receiving-account`);
        dispatch(GET_ALL_RECEIVING_ACCOUNTS(response.data.accountsWithCounts));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllBanks = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/get-all-banks`);
        dispatch(GET_ALL_BANKS(response.data.banks));
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteAccount = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await fetch(`${currentAPI}payments/${currentUnit?._id}/delete-receiving-account/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Account Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getFeeDetails = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/fees/details/${id}?${queries}`,
        );
        dispatch(GET_FEE_DETAILS(response.data.data));
        return response.data.data;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const publishFee = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/fees/publish/${id}`,
            payload,
        );
        const successMessage = response?.data?.data.message || 'Fee Published Successfully!';
        toast.success(successMessage);
        return true;
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteFee = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}payments/${currentUnit?._id}/fees/delete/${id}`);
        const successMessage = response?.data.message || 'Fee Deleted Successfully!';
        if (response.status === 200) {
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPaidFull = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/fees/paidfull/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_PAID_DETAILS(response.data));
            return response.data.data;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getUnpaidFull = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/fees/notpaid/${id}?${queries}`,
        );
        if (response.status === 200) {
            const transformedData = response?.data?.data?.map((data) => ({
                ...data,
                faculty: data?.college || data?.school || data?.faculty,
                amountPayable: response?.data?.amountPayable,
            }));
            dispatch(GET_UNPAID_DETAILS(response.data));
            return transformedData;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getInstallmentPaid = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/fees/notfull/${id}?${queries}`,
        );
        if (response.status === 200) {
            const transformedData = response?.data?.data?.map((data) => ({
                ...data,
                faculty: data?.college || data?.school || data?.faculty,
            }));
            dispatch(GET_INSTALLMENT_DETAILS(response.data));
            return transformedData;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getPaymentHistory = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/payment-history`);
        if (response.status === 200) {
            dispatch(GET_PAYMENT_HISTORY(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getStudentPaymentHistory = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/student-payment-history/${id}?${queries}`,
        );
        if (response.status === 200) {
            dispatch(GET_STUDENT_PAYMENT_HISTORY(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getStudentReceipt = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/student-payment-receipt/${id}`,
        );
        if (response.status === 200) {
            dispatch(GET_STUDENT_RECEIPT(response.data.data));
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getFeeById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/fees/${id}`);
        if (response.status === 200) {
            dispatch(GET_FEE(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const searchStudents = (search) => async (dispatch, getState) => {
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/get-students?search=${search}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.clear(error);
    }
};

export const getDepartments = (id, name) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const res = await axiosInstance.get(`${currentAPI}faculty/${currentUnit?._id}/department/${id}`);
        const data = res.data.data;
        return { name, id, data };
    } catch (error) {
        return error;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editFee = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/fees/edit/${id}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const configurePaymentGateway = (payload) => async (dispatch, getState) => {
    // dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}payments/add-paystack-keys`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        // dispatch(STOP_LOADING());
    }
};

export const getAllActivePaymentGateways = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/find-all-active`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActivePaymentGatewayById = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}payments/find/${id}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        return errorMessage;
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDebtors = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/debts`);
        if (response.status === 200) {
            dispatch(GET_ALL_DEBTORS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDebtor = (studentId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/debts/${studentId}`);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const addDebtor = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/debts`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully created');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDebtor = (payload, debtId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}payments/${currentUnit?._id}/debts/${debtId}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully updated');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDebtor = (debtId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}payments/${currentUnit?._id}/debts/${debtId}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Debtor successfully deleted');
            dispatch(getAllDebtors());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDebtorFee = (debtId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}payments/${currentUnit?._id}/debts/${debtId}`, {
            data: payload,
        });

        if (response.status === 201 || response.status === 200) {
            toast.success('Fee successfully deleted');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllDiscounts = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/discounts?${queries}`);
        if (response.status === 200) {
            dispatch(GET_ALL_DISCOUNTS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getDiscountDetails = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/discounts/details/${id}`);
        if (response.status === 200) {
            dispatch(GET_DISCOUNT_DETAILS(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        dispatch(GET_DISCOUNT_DETAILS([]));
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        console.clear(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createDiscounts = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/discounts`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully created');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const applyDiscounts = (id, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/discounts/${id}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully applied');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteDiscount = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}payments/${currentUnit?._id}/discounts/${id}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully deleted');
            dispatch(getAllDiscounts());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStudentFromDiscount = (studentId, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/discounts/${feeId}/${studentId}`,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully deleted');
            dispatch(getAllDiscounts());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editDiscount = (payload, discountId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/discounts/${discountId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Discount successfully updated');

            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const createAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/agents`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent successfully created');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const editAgent = (payload, id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(`${currentAPI}payments/${currentUnit?._id}/agents/${id}`, payload);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const deleteAgent = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.delete(`${currentAPI}payments/${currentUnit?._id}/agents/${id}`);

        if (response.status === 201 || response.status === 200) {
            toast.success('Agent deleted successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllAgents = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}payments/${currentUnit?._id}/agents?${queries}`);

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_AGENTS(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAgentDetails = (id, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();
        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}payments/${currentUnit?._id}/agents/details/${id}?${queries}`,
        );

        if (response.status === 201 || response.status === 200) {
            // toast.success('Agent successfully created');
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const attachFeeToAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}payments/${currentUnit?._id}/agents/attach-fee`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Fee attached successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getAllAgentFee = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}payments/${currentUnit?._id}/agents/all-fees`, payload);

        if (response.status === 201 || response.status === 200) {
            dispatch(GET_ALL_AGENT_FEES(response.data.data));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const attachStudentToAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}admission/${currentUnit?._id}/agents/assign_student`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) attached to agent successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const detachStudentFromAgent = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}admission/${currentUnit?._id}/agents/detach_student`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) detached from agent successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const attachStudentToFee = (payload, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/fees/add-student/${feeId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student(s) attached to fee successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAllBanksFromPaystack = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${process.env.REACT_APP_PAYSTACK_URL}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
            },
        });
        const parsedResponse = await response.json();

        return parsedResponse?.data;
    } catch (error) {
        const errorMessage = error?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const removeStudentFromFee = (payload, feeId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.patch(
            `${currentAPI}payments/${currentUnit?._id}/fees/remove-student/${feeId}`,
            payload,
        );

        if (response.status === 201 || response.status === 200) {
            toast.success('Student removed from fee successfully!');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
