import AddNewHostel from '../../components/forms/add-new-hostel';
import CreateBlock from '../../components/forms/create-block';
import EditHostel from '../../components/forms/edit-hostel';
import { STUDENT_AFFAIRS } from '../../utils/unitNames';
import {
    AcademicCalendar,
    // AddAdministrator,
    AddDistributionList,
    Administrator,
    CreateRole,
    DistributionList,
    EditAdministrator,
    EditRole,
    Received,
    Roles,
    GeneralSettings,
    Staff,
    StaffDetail,
    UpdateDistributionList,
    Access,
    AssignRole,
    ComposeCommunications,
    CommsSettings,
    ComposeForm,
    ViewCommunications,
    HostelManagement,
} from '../../views';
import Student from '../../views/admission/students';
import StudentById from '../../views/admission/students/studentDetails';
import Exeat from '../../views/exeat';
import HostelBlock from '../../views/hostel/hostel-management/hostel-block';
import HostelRoom from '../../views/hostel/hostel-management/hostel-room';

export const studentAffairsRoutes = [
    {
        path: '/student-affairs/academic-calendar',
        element: <AcademicCalendar />,
    },
    {
        path: '/student-affairs/access',
        element: (
            <Access
                staffs={<Staff unitName={STUDENT_AFFAIRS} />}
                administrators={<Administrator unitName={STUDENT_AFFAIRS} />}
                roles={<Roles unitName={STUDENT_AFFAIRS} />}
            />
        ),
    },
    {
        path: '/student-affairs/access/add-administrator',
        // element: <AddAdministrator unitName={STUDENT_AFFAIRS} />,
        element: <AssignRole unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/access/edit-administrator',
        element: <EditAdministrator unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/access/create-role',
        element: <CreateRole unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/access/edit-role',
        element: <EditRole unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/access/staff/:id',
        element: <StaffDetail />,
    },
    {
        path: 'student-affairs/communications/received',
        element: <Received unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/settings',
        element: <GeneralSettings />,
    },
    {
        path: '/student-affairs/communications/distribution-list',
        element: <DistributionList unitName={STUDENT_AFFAIRS} title="Communications" />,
    },
    {
        path: '/student-affairs/communications/distribution-list/add',
        element: <AddDistributionList unitName={STUDENT_AFFAIRS} title="Communications" />,
    },
    {
        path: '/student-affairs/communications/distribution-list/edit/:id',
        element: <UpdateDistributionList unitName={STUDENT_AFFAIRS} title="Communications" />,
    },
    {
        path: '/student-affairs/exeat',
        element: <Exeat unitName={STUDENT_AFFAIRS} title="Exeat" />,
    },
    {
        path: '/student-affairs/students',
        element: <Student />,
    },
    {
        path: '/student-affairs/students/:id',
        element: <StudentById />,
    },
    {
        path: '/student-affairs/communications/compose',
        element: <ComposeCommunications />,
    },
    {
        path: '/student-affairs/communications/settings',
        element: <CommsSettings />,
    },
    {
        path: '/student-affairs/communications/compose/create',
        element: <ComposeForm />,
    },
    {
        path: '/student-affairs/communications/compose/view/:id',
        element: <ViewCommunications />,
    },
    {
        path: '/student-affairs/accomodation-management',
        element: <HostelManagement />,
    },
    {
        path: '/student-affairs/accomodation-management/:id',
        element: <HostelBlock unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/accomodation-management/:id/create-block',
        element: <CreateBlock unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/accomodation-management/:id/:id',
        element: <HostelRoom unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/accomodation-management/create-hostel',
        element: <AddNewHostel unitName={STUDENT_AFFAIRS} />,
    },
    {
        path: '/student-affairs/accomodation-management/edit-hostel',
        element: <EditHostel unitName={STUDENT_AFFAIRS} />,
    },
];
