const currentEnv = process.env.REACT_APP_ENV;

export const studentAffairsNavLinks = [
    {
        title: 'Communications',
        to: '/student-affairs/communications',
        subMenu: [
            {
                title: 'Compose',
                to: '/student-affairs/communications/compose',
            },
            {
                title: 'Received',
                to: '/student-affairs/communications/received',
            },
            {
                title: 'Distribution List',
                to: '/student-affairs/communications/distribution-list',
            },
            {
                title: 'Settings',
                to: '/student-affairs/communications/settings',
            },
        ],
    },
    {
        title: 'Students',
        to: '/student-affairs/students',
    },
    {
        title: 'Academic Calendar',
        to: '/student-affairs/academic-calendar',
    },
    {
        title: 'Access',
        to: '/student-affairs/access',
    },

    ...(currentEnv !== 'production'
        ? [
              {
                  title: 'Exeat',
                  to: '/student-affairs/exeat',
              },
          ]
        : []),
    {
        title: 'Accomodation Management',
        to: '/student-affairs/accomodation-management',
    },
    {
        title: 'Settings',
        to: '/student-affairs/settings',
    },
];

export const studentAffairsRoutes = studentAffairsNavLinks.map((item) => item.to);
